import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { ModalStore, IModalParams } from '~/store/modal'

@Component
export class ModalFunctions extends Vue {
    @ModalStore.State('activeModal') activeModal: any

    SET_ACTIVE_MODAL({ modal, payload }: IModalParams) {
        this.$store.commit('modal/SET_ACTIVE_MODAL', { modal, payload: payload || null })
    }

    closeModal() {
        this.SET_ACTIVE_MODAL({ modal: null })
    }
}
