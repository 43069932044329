




















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class LicenseOptionBoxSimple extends Vue {
    @Prop({ type: Object }) license: ILicenseOptionBeat
    @Prop({ type: Number }) boughtLicensePrice: number
    @Prop({ type: String }) producerName: string

    get licenseIsAlreadyInCart() {
        /**
         * @param {this.license} Object prop passed by parent
         */
        const beatId = this.$store.state.modal.activeModal.payload.id
        const cartMatch = this.$store.state.cart.cart.find((item: ICartItem) => item.beat.id === beatId)
        if (cartMatch) return cartMatch.selected_license_option.id === this.license.id
    }
}
