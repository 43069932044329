

























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class LicenseOptionBoxDetailed extends Vue {
    @Prop({ type: Object }) license: ILicenseOptionBeat
    @Prop({ type: Number }) exclusiveLicensePrice: number
    @Prop({ type: Number }) boughtLicensePrice: number
    @Prop({ type: String }) producerName: string

    get calculatedPrice() {
        let price
        // if it's an exlusive, select the price added by the producer inside the beat (if there is one) otherwise use default exclusive price
        if (this.license.is_exclusive && this.exclusiveLicensePrice) price = this.exclusiveLicensePrice
        else price = this.license.price
        return Math.max(price - (this.boughtLicensePrice || 0), 0)
    }

    get saleUnitsConverted() {
        if (this.license.sale_units === 0) return 'Unlimited'
        return this.license.sale_units.toLocaleString()
    }
    get streamUnitsConverted() {
        if (this.license.stream_units === 0) return 'Unlimited'
        return this.license.stream_units.toLocaleString()
    }
}
