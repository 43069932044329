import { Vue, Component } from 'vue-property-decorator'

@Component
export class BoughtLicense extends Vue {
    get boughtLicense() {
        // retrive bought beats
        const boughtBeats: IBoughtBeat[] = this.$store.state.user.boughtBeats
        if (this.$store.getters['auth/isAuthenticated'] && boughtBeats) {
            // find the beat match inside the bought beats
            // @ts-ignore
            const boughtBeat = boughtBeats.find(item => item.beat.id === this.beat.id)
            return boughtBeat ? boughtBeat.license : null
        }
        return null
        // we need the if statement or it gives error during evaluation becuase this would return undefined
    }
}
