























































import { Prop, Component, Mixins } from 'vue-property-decorator'

import CartItemBeat from '~/components/CartItemBeat.vue'
import LicenseOptionBoxSimple from '@/components/LicenseOptionBoxSimple.vue'
import LicenseOptionBoxDetailed from '@/components/LicenseOptionBoxDetailed.vue'
// mixins
import { BoughtLicense } from '@/mixins/cart'
import { ModalFunctions } from '~/mixins/modals'
// vuex
import { CartStore } from '~/store/cart'
import { AuthStore } from '~/store/auth'
import { fetchSingleProducer } from '@/api/producers'
import { fetchProducerLicenseOptions } from '@/api/producers'
import { fetchLicenseSummary } from '~/api/pdf'

@Component({ components: { CartItemBeat, LicenseOptionBoxSimple, LicenseOptionBoxDetailed } })
export default class LicenseOptionsSelection extends Mixins(BoughtLicense, ModalFunctions) {
    @Prop({ type: Object }) beat: IBeat

    licenseSelectionStep = 1
    licenseOptions: ILicenseOptionBeat[] = []
    selectedLicenseOption: ILicenseOptionBeat = null

    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
    @CartStore.Action('addToCart') addToCart: (cartItem: ICartItem) => void

    get selectedItem(): ICartItem {
        // passed to the add to cart method
        return {
            beat: this.beat,
            selected_license_option: this.selectedLicenseOption
        }
    }

    get noDeals() {
        if (!this.licenseOptions) return null
        return this.licenseOptions.every(v => v.deal_get === 0)
    }

    get sameDealForAllLicenses() {
        if (!this.licenseOptions) return null
        // remove "Exclusive" licenses from the array. If they are all exclusive, return
        const licensesWithoutExclusives = this.licenseOptions.filter(item => item.is_exclusive === false)
        if (licensesWithoutExclusives.length === 0) return null
        // check if the remaining licenses have same deal_buy & deal_get
        const dealBuy = licensesWithoutExclusives.every(v => v.deal_buy === licensesWithoutExclusives[0].deal_buy)
        const dealGet = licensesWithoutExclusives.every(v => v.deal_get === licensesWithoutExclusives[0].deal_get)
        // and they do and the deal get is bigger or equal to 1, return obj of deal_buy and deal_get
        if (dealBuy && dealGet && licensesWithoutExclusives[0].deal_get >= 1) {
            return {
                dealBuy: licensesWithoutExclusives[0].deal_buy,
                dealGet: licensesWithoutExclusives[0].deal_get
            }
        }
        return null
    }

    async openPdfLicenseSummary(producerSlug: IProducer['slug']) {
        // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
        const pdfFile = await fetchLicenseSummary(producerSlug)
        const url = window.URL.createObjectURL(new Blob([pdfFile]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `license-summary.pdf`)
        document.body.appendChild(link)
        link.click()
    }

    async fetchLicenseOptions() {
        const licenseOptions = await fetchProducerLicenseOptions(this.beat.producer.slug)
        licenseOptions.sort((a, b) => (a.value !== b.value ? a.value - b.value : a.price - b.price))
        this.licenseOptions = licenseOptions
    }

    selectLicense(license: ILicenseOptionBeat) {
        this.selectedLicenseOption = license
        this.licenseSelectionStep++
        this.addToCart(this.selectedItem)
    }

    exitLicenseOptionsSelectionModal({ goToCart }: { goToCart: boolean }) {
        if (goToCart) this.$router.push({ name: 'Cart' })
        this.closeModal()
    }

    mounted() {
        this.licenseSelectionStep = 1
        this.fetchLicenseOptions()
    }
}
